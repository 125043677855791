@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-main-black;
  margin: 0;
  padding: 0;
}

#root {
  @apply bg-main-black;
  min-height: 100vh;
}

.background-circle {
  position: absolute;
  width: 800px;
  height: 800px;
  background: radial-gradient(
    circle,
    rgba(133, 199, 66, 0.05) 0%,
    transparent 80%
  );
  border-radius: 50%;
  pointer-events: none;
  z-index: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
